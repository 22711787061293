import { ScanModel } from 'core/models';
import { getToken, parseValue } from 'utils/helper';
import { logger } from 'utils/logger';

export const getScan = async(id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/scans/${id}`;

    const formData = JSON.stringify({});

    logger.log(' GETTING SCAN DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                const keys = Object.keys(item);
                var newItem = {};
                newItem = parseValue(item)
                newItem.id = newItem.scanId;
                
                // keys.forEach((key, idx) => {
                //     let tmpKey = key.replace(' ', '');
                //     tmpKey = key.replace(':', '_');
                //     // lowercase the first.
                //     let char = tmpKey.charAt(0).toLowerCase();
                //     let subStr = tmpKey.substring(1, tmpKey.length);
                //     const newKey = `${char}${subStr}`;
                //     newItem[newKey] = item[key]['S'];
                //     newItem.id = newItem.scanId;
                // });
                return new ScanModel(newItem);
            });
            if (items.length) {
                return items[0];
            } else {
                throw new Error('No scan found');
            }
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR getting scan', err);
            throw err;
        });
};

export const getScans = async (criteria, token) => {
    var url = `${process.env.REACT_APP_API_URL}/scans`;

    if (criteria) {
        if (criteria.styleCode) {
            url += `/style/${criteria.styleCode}`;
        }
        if (criteria.status) {
            url += `/${criteria.status}`;
        }
    }

    const formData = JSON.stringify({});

    logger.log(' GETTING SCANS DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                // const keys = Object.keys(item);
                var newItem = parseValue(item);
                newItem.id = newItem.scanId;
                // keys.forEach((key, idx) => {
                //     let tmpKey = key.replace(' ', '');
                //     tmpKey = key.replace(':', '_');
                //     // lowercase the first.
                //     let char = tmpKey.charAt(0).toLowerCase();
                //     let subStr = tmpKey.substring(1, tmpKey.length);
                //     const newKey = `${char}${subStr}`;
                //     newItem[newKey] = item[key]['S'];
                //     newItem.id = newItem.scanId;
                // });
                return new ScanModel(newItem);
            });

            logger.log(items, ' getScans');
            return items;
        })
        .catch((err) => {
            console.error('ERROR getting scans', err);
            throw err;
        });
};

export const udpateScan = async (scan) => {
    let url = scan.id ? `${process.env.REACT_APP_API_URL}/scans/${scan.id}` : `${process.env.REACT_APP_API_URL}/scans`;
    let tmpData = { ...scan };
    delete tmpData.id;
    delete tmpData.filename;
    delete tmpData.location;
    delete tmpData.filepath;
    delete tmpData.lon;
    delete tmpData.lat;
    delete tmpData.scanId;
    delete tmpData.url;
    delete tmpData.upBucket;
    delete tmpData.did;
    delete tmpData.ext;
    delete tmpData.concat;

    let formData = JSON.stringify({
        ...tmpData,
    });

    logger.log(' UPDATING SCAN DATA: ', formData);
    
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            if(data.__type){
                throw(new Error('ERROR updating scan'))
            }
            return data;
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR updating scan', err);
            throw err;
        });
};


export const uploadCSV = async ( file) => {
    let url = `${process.env.REACT_APP_API_URL}/scans/upload`;
    return true;
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'hgio-filename':`${file.name}`
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            if (response.ok) {
                return true;
            }
            throw new Error('ERROR uploading CSV scans');
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logger.error('ERROR uploading CSV scans', err);
            throw err;
        });
};