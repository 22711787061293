import { IconZoomIn } from "@tabler/icons";
import { useState } from "react";

const { Group, Overlay, ActionIcon, Skeleton, createStyles, Image } = require("@mantine/core");

const useStyles = createStyles((theme, _params, getRef) => ({
    preview: {
        '.overlay': {
            opacity: 0,
        },
        '&:hover': {
            '.overlay': {
                opacity: 0.5,
            },
            '.btn-clear': {
                opacity: 1,
                marginTop: 0,
            },
        },
        '.btn-clear': {
            opacity: 0,
            marginTop: 10,
            transition: 'all .2s ease-in-out',
        },
    },
}));

const ImagePreview = ({ file, onClick, height = 200, width=180 }) => {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    
    return (
        <Group className={classes.preview} sx={{ position: 'relative', minWidth:width }} align='center' position='center' onClick={() => onClick({ previewHi: file.url, originalFilename: file.name })}>
            
            {isLoading ? (
                <Skeleton width={width} height={height} sx={{position:'absolute', zIndex:1}} />
            ) : (
                null
            )}

            <Image
                fit='contain'
                src={file.url}
                className={classes.image}
                alt={file.name}
                height={height}
                withPlaceholder
                onLoadCapture={(e) => {
                    setIsLoading(false);
                }}
            />

            <Overlay className='overlay' color='#000' />
            <ActionIcon variant='filled' radius={'xl'} size={'xl'} className='btn-clear' sx={{ position: 'absolute', zIndex: 9999 }}>
                <IconZoomIn />
            </ActionIcon>
        </Group>
    );
};

export default ImagePreview