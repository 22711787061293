import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Image, Skeleton, Text } from '@mantine/core';
import { getImageFile } from 'utils/helper';
import { logger } from 'utils/logger';

const imageCache = new Map();

// Cache duration - 1 hour in milliseconds
const CACHE_DURATION = 60 * 60 * 1000;

// Cache helper functions
const getCacheItem = (key) => {
    const item = imageCache.get(key);
    if (!item) return null;

    if (Date.now() > item.expiry) {
        // Cache expired, clean it up
        URL.revokeObjectURL(item.url);
        imageCache.delete(key);
        return null;
    }

    return item.url;
};

const setCacheItem = (key, url) => {
    const expiry = Date.now() + CACHE_DURATION;
    imageCache.set(key, { url, expiry });
};

const ResolverImage = React.memo(({ 
    src = '', 
    onLoaded, 
    width = '100%', 
    height = '100%', 
    classes = {}, 
    alt = '', 
    radius = 0, 
    fit = 'cover' 
}) => {
    const [imageState, setImageState] = useState({
        url: getCacheItem(src) || '',
        isLoading: !getCacheItem(src),
        error: null,
    });

    const cacheKey = useMemo(() => src, [src]);

    const loadImage = useCallback(async () => {
        if (!src) return;

        const cachedUrl = getCacheItem(cacheKey);
        if (cachedUrl) {
            setImageState({
                url: cachedUrl,
                isLoading: false,
                error: null
            });
            return;
        }

        try {
            const response = await getImageFile(src);
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            
            setCacheItem(cacheKey, imageUrl);
            
            setImageState({
                url: imageUrl,
                isLoading: false,
                error: null,
            });

            if (onLoaded) onLoaded(imageUrl);
        } catch (error) {
            console.error('Error loading image:', error);
            setImageState(prev => ({
                ...prev,
                isLoading: false,
                error: 'Failed to load image',
            }));
        }
    }, [src, cacheKey, onLoaded]);

    // Clean up expired cache items periodically
    useEffect(() => {
        const cleanup = () => {
            for (const [key, item] of imageCache.entries()) {
                if (Date.now() > item.expiry) {
                    URL.revokeObjectURL(item.url);
                    imageCache.delete(key);
                }
            }
        };

        // Run cleanup every 5 minutes
        const intervalId = setInterval(cleanup, 10 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        loadImage();
        
        // Cleanup function
        return () => {
            const cachedItem = imageCache.get(cacheKey);
            if (imageState.url && (!cachedItem || Date.now() > cachedItem.expiry)) {
                URL.revokeObjectURL(imageState.url);
            }
        };
    }, [loadImage, cacheKey]);

    
    // useEffect(() => {
    //     logger.log('Cache size:', imageCache.size);
    //     logger.log('Cache contents:', Array.from(imageCache.entries()));
    // }, []);

    return (
        <div style={{ 
            width, 
            height,
            transition: 'opacity 0.2s ease-in-out'
        }}>
            {imageState.isLoading ? (
                <Skeleton width={width} height={height} />
            ) : imageState.error ? (
                <div>
                    <Text size={'sm'} color='dimmed'>Error: {imageState.error}</Text>
                </div>
            ) : (
                <>
                    <Image 
                        fit={fit}
                        className={classes}
                        src={imageState.url}
                        alt={alt}
                        radius={radius}
                        height={height}
                        width={width}
                        withPlaceholder
                        style={{ opacity: imageState.url ? 1 : 0 }}
                    />
                    {/* <Text 
                        style={{wordBreak:'break-all'}} 
                        size={10}
                    >
                        {src}
                    </Text> */}
                </>
            )}
        </div>
    );
});

ResolverImage.displayName = 'ResolverImage';

export default ResolverImage;