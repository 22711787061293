import { ActionIcon, Anchor, Avatar, Badge, Box, Container, Grid, Group, Indicator, Paper, SimpleGrid, Skeleton, Table, Text, Title, createStyles, useMantineTheme } from '@mantine/core';
import { connect } from 'react-redux';
import { CaptureThumb } from '../../../../core/components';
import { selectStyle } from '../../styleSlice';
import { AppConstants, DataFormats } from '../../../../utils/constants';
import { logger } from 'utils/logger';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IconArrowDownRight, IconArrowUpRight, IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { getStatusColor, getStyleStatusColor, getUserAvatarColour, getUserInitials, headerDateSort } from 'utils/helper';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import ResolverImage from 'core/components/resolverImage/ResolverImage';
import VisTimeline from 'core/components/charting/timeline/VisTimeline';
import { DataSet } from 'vis-timeline/standalone';
import { createRoot } from 'react-dom/client';
import { StyleAssetStatusTypes } from 'core/models/StyleAssetFilterModel';

const useStyles = createStyles((theme) => ({
    label: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    svgChart: {
        '.timeline-label': {
            fill: 'white',
            fontSize: 10,
        },
    },
    visChart: {
        '.vis-labelset .vis-label': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
            paddingRight: 20,
            fontWeight: 700,
            fontSize: 14,
            paddingLeft: 10,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.vis-time-axis .vis-text': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[5],
            fontSize: 14,
            '&.vis-minor': {
                borderBottom: '1px solid',
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
            },
        },
        '.vis-panel.vis-center,.vis-panel.vis-left,.vis-panel.vis-right,.vis-panel.vis-top,.vis-panel.vis-bottom, .vis-foreground .vis-group': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.vis-panel.vis-bottom, .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.vis-time-axis .vis-grid.vis-minor, .vis-time-axis .vis-grid.vis-major': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.vis-timeline': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
        },
    },
    seasons: {
        '&.vis-item': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.blue[6], 0.65) : theme.fn.rgba(theme.colors.blue[6], 0.9),
            borderColor: theme.colors.blue[4],
            fontSize: 14,
            borderRadius: `${theme.radius.md}px !important`,
        },
    },
    sessions: {
        '&.vis-item': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.grape[4], 0.6) : theme.fn.rgba(theme.colors.grape[8], 0.9),
            borderColor: theme.colors.grape[6],
            color: theme.colors.grape[2],
            fontSize: 14,
            borderRadius: `${theme.radius.md}px !important`,
        },
    },
    styles: {
        '&.vis-item': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.yellow[4], 0.6) : theme.fn.rgba(theme.colors.yellow[8], 0.9),
            borderColor: theme.colors.yellow[6],
            color: theme.colors.yellow[2],
            fontSize: 14,
            borderRadius: `${theme.radius.md}px !important`,
        },
    },
    seasonSlim: {
        '&.vis-item': {
            backgroundColor: theme.colors.blue[4],
            borderColor: theme.colors.blue[4],
            fontSize: 14,
            borderRadius: `${theme.radius.md}px !important`,
            height: 5,
        },
    },
    toolTip: {
        margin: -7,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        borderRadius: theme.radius.sm,
        padding: theme.spacing.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[9],
    },
}));

function findLatestDate(items, key = 'end') {
    // Check if the array is not empty
    if (items.length === 0) {
        return null;
    }

    // Use reduce to find the latest date
    return items.reduce((latest, item) => {
        const itemDate = new Date(item[key]);
        return itemDate > latest ? itemDate : latest;
    }, new Date(items[0][key])); // Initialize with the date of the first item
}

const StyleTabOverview = ({ user, style, mediaFilter, guides, success, error, form }) => {
    const [data, setData] = useState([]);
    const theme = useMantineTheme();

    const momentDate = moment(style?.completed, 'YYYY-MM-DD-HH-mm-ss-SSS');
    const jsDate = momentDate.toDate();
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const statData = [
        { diff: 23, title: 'Events', value: style?.timeline?.seasons.length, color: theme.colors.blue[6] },
        { diff: -23, title: 'Sessions', value: style?.timeline?.sessions.length, color: theme.colors.grape[6] },
        { diff: 23, title: 'Masters', value: style?.timeline?.masters.length, color: theme.colors.pink[6] },
        { diff: 23, title: 'Assets', value: style?.timeline?.styles.length, color: theme.colors.yellow[6] },
    ];

    const [chartData, setChartData] = useState({ items: null, groups: null });
    const [timelineDates, setTimelineDates] = useState({ start: null, end: null });

    function convertToTimestamp(dateString) {
        if (!dateString) {
            return;
        }
        // Parse the date string using the format
        const date = moment(dateString, 'YYYY-MM-DD-HH-mm-ss');

        // Check if the date is valid
        if (!date.isValid()) {
            throw new Error('Invalid date string format');
        }

        // Return the Unix timestamp (seconds since epoch)
        return date;
    }

    useEffect(() => {
        var cd = [];
        if (style?.timeline) {
            setIsLoading(false);

            var sessions = [];
            var seasons = [];
            var styles = [];

            var items = new DataSet({
                type: { start: 'ISODate', end: 'ISODate' },
            });

            style.timeline.seasons.forEach((val, idx) => {
                if (val.created) {
                    const startTime = convertToTimestamp(val.created).unix();
                    const endTime = convertToTimestamp(val.created).add(100, 'days').unix();
                    const formattedDate = moment(val.created, 'YYYY-MM-DD-HH-mm-ss').format('YYYY-MM-DDTHH:mm:ss');

                    // find the assets for the season
                    const seasonAssets = style.timeline.styles.filter((tmpStyle) => tmpStyle.seasonCode === val.seasonPart);

                    const latestD = findLatestDate(seasonAssets, 'completed');
                    const formattedEndDate = moment(convertToTimestamp(val.created).add(5, 'days'), 'YYYY-MM-DD-HH-mm-ss').format('YYYY-MM-DDTHH:mm:ss');

                    logger.log(startTime);
                    const item = {
                        id: `${val.seasonPart.replace('-', '_')}`,
                        name: val.seasonName,
                        label: `${val.seasonName} - ${startTime}`,
                        content: `${val.seasonPart} - ${val.seasonName}`,
                        className: classes.seasons,
                        itemType: 'season',
                        // type: 'bar',
                        start: formattedDate,
                        end: latestD,
                        times: [{ color: theme.colors.blue[5], starting_time: startTime, ending_time: endTime, concentration: '5.0 ng/mL' }],
                    };
                    cd.push(item);
                    seasons.push(item);
                }
            });

            style.timeline.sessions.forEach((val, idx) => {
                if (val.created) {
                    const startTime = convertToTimestamp(val.created).unix();
                    const endTime = convertToTimestamp(val.created).add(1, 'days').unix();

                    const formattedDate = moment(val.created, 'YYYY-MM-DD-HH-mm-ss').format('YYYY-MM-DDTHH:mm:ss');

                    var season;
                    style.timeline.seasons.forEach((sea) => {
                        var idx = sea.studioSessionCodes?.indexOf(val.ssPart);
                        if (idx > -1) season = sea;
                    });

                    logger.log(startTime);
                    const item = {
                        id: `${val.ssPart}`,
                        subgroupOrder: idx + 1,
                        label: val.sessionName,
                        name: val.sessionName,
                        content: `Session: ${val.sessionName}`,
                        itemType: 'session',
                        start: formattedDate,
                        group: season ? `${season.seasonPart.replace('-', '_')}` : '',
                        className: classes.sessions,
                        times: [{ color: theme.colors.grape[5], starting_time: startTime, ending_time: endTime, concentration: '5.0 ng/mL' }],
                    };
                    cd.push(item);

                    sessions.push(item);
                }
            });

            style.timeline.styles.forEach((val, idx) => {
                if (val.completed) {
                    const startTime = convertToTimestamp(val.actionSort).unix();
                    const endTime = convertToTimestamp(val.completed).unix();

                    const formattedDate = moment(val.actionSort, 'YYYY-MM-DD-HH-mm-ss').format('YYYY-MM-DDTHH:mm:ss');
                    const formattedEndDate = moment(val.completed, 'YYYY-MM-DD-HH-mm-ss').format('YYYY-MM-DDTHH:mm:ss');

                    logger.log(startTime);
                    const item = {
                        id: idx,
                        name: val.styleSort,
                        label: val.styleSort,
                        subgroupOrder: sessions.length + 1 + idx,
                        content: `${val.originalFileName}`,
                        itemType: 'style',
                        start: formattedDate,
                        end: formattedEndDate,
                        className: classes.styles,
                        group: `${val.seasonCode.replace('-', '_')}`,
                        subgroup: `${val.sessionCode}`,
                        times: [{ color: theme.colors.yellow[5], starting_time: startTime, ending_time: endTime, concentration: '5.0 ng/mL' }],
                        thumb: val.thumb,
                    };
                    cd.push(item);
                    styles.push(item);
                }
            });

            const latestDate = findLatestDate(styles);

            // var groups = new vis.DataSet([{
            //     id: 'bar', content:'bar', subgroupOrder: function (a,b) {return a.subgroupOrder - b.subgroupOrder;}, subgroupStack: {'sg_1': false, 'sg_2': false, 'sg_3': false }
            // },{
            //     id: 'foo', content:'foo', subgroupOrder: 'subgroupOrder' // this group has no subgroups but this would be the other method to do the sorting.
            // }]);

            // function organizeData(events, sessions, styles) {
            //     // Create a map to hold sessions by their IDs
            //     const sessionMap = new Map();
            //     sessions.forEach(session => {
            //         sessionMap.set(session.id, { ...session, styles: [] });
            //     });

            //     // Assign styles to their corresponding sessions
            //     styles.forEach(style => {
            //         const session = sessionMap.get(style.sessionId);
            //         if (session) {
            //             session.styles.push(style);
            //         }
            //     });

            //     // Create a map to hold events by their IDs
            //     const eventMap = new Map();
            //     events.forEach(event => {
            //         eventMap.set(event.id, { ...event, sessions: [] });
            //     });

            //     // Assign sessions to their corresponding events
            //     sessions.forEach(session => {
            //         const event = eventMap.get(session.eventId);
            //         if (event) {
            //             event.sessions.push(sessionMap.get(session.id));
            //         }
            //     });

            //     // Convert the map to an array
            //     return Array.from(eventMap.values());
            // }

            if (latestDate) {
                // only grab seasons that have a start date
                var filteredSeasons = seasons.filter((val) => (val.start ? true : false));
                const timelineStart = filteredSeasons && filteredSeasons.length ? filteredSeasons[0].start : new Date();

                var groups = new DataSet([
                    ...filteredSeasons.map((val) => ({
                        id: val.id,
                        content: val.content,
                        start: val.start,
                        end: latestDate,
                    })),
                ]);
                // const tmpData = organizeData(seasons,sessions,styles);
                items.add(filteredSeasons.map((val) => ({ ...val, id: `${val.id}_a`, group: `${val.id}`, className: classes.seasonSlim, end: latestDate, subgroupOrder: 0 })));
                items.add(sessions.map((val) => ({ ...val, end: latestDate })));
                items.add(styles);
                setChartData({ items, groups });
                setTimelineDates({ start: moment(timelineStart).subtract(0, 'days').format('YYYY-MM-DD'), end: moment(latestDate).add(5, 'days').format('YYYY-MM-DD') });
            }
        }
    }, [style]);

    const mastersColumns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'productName',
                width: 300,
            },
            {
                Header: 'Status',
                accessor: 'productStatus',
            },
            {
                Header: 'Department',
                accessor: 'dept',
            },
            {
                Header: 'Brand',
                accessor: 'brand',
            },
            {
                Header: 'Colour',
                accessor: 'colour',
            },
            {
                Header: 'Variation',
                accessor: 'variation',
            },
            {
                Header: 'Modified',
                accessor: 'modified',
            },
        ],
        [],
    );

    const sessionColumns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'sessionName',
                width: 300,
            },
            {
                Header: 'Department',
                accessor: 'department',
            },
            {
                Header: 'Shoot Date',
                accessor: 'shootDate',
                sortType: (a, b) => headerDateSort(a, b, 'shootDate'),
            },
            {
                Header: 'Due Date',
                accessor: 'deadline',
            },
        ],
        [],
    );

    const seasonColumns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'seasonName',
                width: 300,
            },

            {
                Header: 'Latest Scan',
                accessor: 'scan.location',
            },
            {
                Header: 'Guide',
                accessor: 'guideCode',
            },
            {
                Header: 'Created',
                accessor: 'created',
                sortType: (a, b) => headerDateSort(a, b, 'shootDate'),
            },
        ],
        [],
    );

    // const options = {
    //     width: 1500,
    //     height: 1000,
    //     itemHeight: 20,
    //     // maxZoom:1,
    //     allowZoom: false,
    //     timeIsRelative: false,
    //     timeIsLinear: true,
    //     tickFormat: {
    //         format: function (d) {
    //             const date = moment.unix(d);
    //             return date.format(DataFormats.DATE_FORMAT);
    //         },
    //         tickTime: timeHour,
    //         tickInterval: 10,
    //         tickSize: 10,
    //     },
    //     showAxisCalendarYear: true,
    //     showTodayLine: true,
    //     xScale: 10,
    //     // yScale:.5
    //     // ... other options
    // };

    var visOptions = {
        // orientation:'top'
        editable: false,
        stack: true,
        stackSubgroups: true,
        groupHeightMode: 'fixed',
        start: timelineDates.start,
        end: timelineDates.end,
        order: function (a, b) {
            return b.subgroupOrder - a.subgroupOrder;
        },
        groupOrder: function (a, b) {
            logger.log(' ORDERING');
            return a.subgroupOrder - b.subgroupOrder;
        },
        // subgroupOrder: function (a, b) {
        //     logger.log(' ORDERING');
        //     return a.subgroupOrder - b.subgroupOrder;
        // },
        // xss:{
        //     filterOptions: {
        //     allowList: {
        //       div: ['class', 'id'],
        //       span: ['style'],
        //     },
        //   },
        // },
        tooltip: {
            followMouse: true,
            template: function (item, element, data) {
                var element = this.dom.visibleFrame;

                element.innerHTML = '';

                // Create a root for this element
                const root = createRoot(element);

                switch (item.itemType) {
                    case 'session': {
                        root.render(
                            <div className={classes.toolTip}>
                                <Box>
                                    <Text size={'sm'}>{item.content}</Text>
                                    <Text size={'xs'} color='dimmed'>
                                        {moment(item.start).format(DataFormats.DATE_FORMAT)} - {moment(item.end).format(DataFormats.DATE_FORMAT)}
                                    </Text>
                                </Box>
                            </div>,
                        );
                        break;
                    }
                    case 'style': {
                        root.render(
                            <div className={classes.toolTip}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <ResolverImage style={{ borderRadius: 20 }} width={120} height={140} src={item.thumb} />
                                    <Text align='center' mt={'sm'} size={'xs'}>
                                        {item.content}
                                    </Text>
                                </div>
                            </div>,
                        );
                        break;
                    }
                    default: {
                        root.render(
                            <div className={classes.toolTip}>
                                <Text size={'sm'}>{item.content}</Text>
                            </div>,
                        );
                        break;
                    }
                }

                return element;
            },
        },
    };

    const onRowClick = (event, type) => {
        logger.log(event, data);

        switch (type) {
            case 'season': {
                navigate(`/seasons/${event.seasonPart}`);
                break;
            }

            case 'session': {
                navigate(`/sessions/${event.ssPart}`);
                break;
            }
        }
    };

    const sortedStyles = React.useMemo(() => {
        logger.log(style);
        if (!style?.assets) return [];

        return [...style.assets]
            .filter((val) => {
                return val.viewstatus && val.viewstatus === StyleAssetStatusTypes.ARCHIVED ? false : true;
            })
            .sort((a, b) => {
                const getSecondPart = (str) => {
                    const parts = str.assignedSequence.split('_');
                    return parts.length > 1 ? parseInt(parts[1], 10) : 9999;
                };

                const aValue = getSecondPart(a);
                const bValue = getSecondPart(b);

                return aValue - bValue;
            });
    }, [style]);

    return (
        <Container fluid sx={{ marginLeft: 0 }} pt={'lg'}>
            <Grid>
                <Grid.Col md={12} lg={9}>
                    <StatsGridIcons isLoading={isLoading} data={statData} />

                    <Paper my={'lg'} p='xl' withBorder>
                        <Title order={5} mb='lg'>
                            Timeline
                        </Title>
                        <VisTimeline options={visOptions} className={classes.visChart} data={chartData} />
                        {/* <Timeline className={classes.svgChart} data={chartData} options={options} /> */}
                    </Paper>

                    <Box align='start'>
                        <Group align='start'>
                            <Paper p={'lg'} style={{ flex: 1 }} withBorder>
                                <Group mb={'md'}>
                                    <Title order={4}>Events</Title>
                                    <Badge>{style?.timeline?.seasons?.length}</Badge>
                                </Group>
                                <CustomTable
                                    onItemClick={(event) => onRowClick(event, 'season')}
                                    isLoading={isLoading}
                                    selectedItems={[]}
                                    columns={seasonColumns}
                                    data={style?.timeline?.seasons ?? []}
                                />
                            </Paper>

                            <Paper p={'lg'} style={{ flex: 1 }} withBorder>
                                <Group mb={'md'}>
                                    <Title order={4}>Sessions</Title>
                                    <Badge>{style?.timeline?.sessions.length}</Badge>
                                </Group>
                                <CustomTable
                                    onItemClick={(event) => onRowClick(event, 'session')}
                                    isLoading={isLoading}
                                    selectedItems={[]}
                                    columns={sessionColumns}
                                    data={style?.timeline?.sessions ?? []}
                                />
                            </Paper>
                        </Group>

                        <Paper my={'lg'} p={'lg'} style={{ flex: 1 }} withBorder>
                            <Group mb={'md'}>
                                <Title order={4}>Masters</Title>
                                <Badge>{style?.timeline?.masters.length}</Badge>
                            </Group>
                            <CustomTable onItemClick={(event) => onRowClick(event, 'master')} isLoading={isLoading} selectedItems={[]} columns={mastersColumns} data={style?.timeline?.masters ?? []} />
                        </Paper>
                    </Box>
                </Grid.Col>

                <Grid.Col md={12} lg={3}>
                    <Paper p={'lg'} mb={'lg'}>
                        <Group mb={'md'}>
                            <Title order={4}>Summary</Title>
                            {/* <Badge>{style?.assets?.length}</Badge> */}
                        </Group>
                        <Box className={classes.imageSection} mb={'lg'}>
                            {/* <Image src="https://i.imgur.com/ZL52Q2D.png" /> */}
                            {/* <IconShirt size={72} stroke={1} /> */}
                            <div style={{ minHeight: 300 }}>{style?.assets ? <ResolverImage style={{ borderRadius: 20 }} width={'100%'} height={'100%'} src={sortedStyles[0]?.preview} /> : null}</div>
                            <Group align='start' noWrap>
                                <Box mt={'lg'}>
                                    <Group position='apart'>
                                        <div>
                                            <Text fw={500}>{style?.name}</Text>
                                            <Text color='dimmed' size={'sm'} lineClamp={2}>
                                                {style?.id}
                                            </Text>
                                        </div>
                                        <Badge color={getStyleStatusColor(style?.status ?? '')} variant='light'>
                                            {style?.status}
                                        </Badge>
                                    </Group>

                                    <Group position='apart' align='start'>
                                        <Box className={classes.section} mt='md'>
                                            <Text size='sm' c='dimmed' className={classes.label}>
                                                Department
                                            </Text>
                                            <Text size={'sm'} className={classes.label}>
                                                {style?.dept ? `${style.deptCode ?? ''} - ${style.dept}` : '-'}
                                            </Text>
                                        </Box>

                                        <Box className={classes.section} mt='md'>
                                            <Text size='sm' c='dimmed' className={classes.label}>
                                                Season
                                            </Text>
                                            <Text size={'sm'} className={classes.label}>
                                                {style?.seasonCode}
                                            </Text>
                                        </Box>

                                        <Box className={classes.section} mt='md'>
                                            <Text size='sm' c='dimmed' className={classes.label}>
                                                Completed
                                            </Text>
                                            <Text size={'sm'}>{moment(jsDate).format(DataFormats.DATE_FORMAT)}</Text>
                                        </Box>
                                    </Group>
                                </Box>
                            </Group>
                        </Box>

                        <Box>
                            <Group mb={'md'}>
                                <Title order={4}>Assets</Title>
                                <Badge>{sortedStyles?.length}</Badge>
                            </Group>
                            <div style={{ width: '100%' }}>
                                <Group spacing={0}>
                                    {sortedStyles.map((val, idx) => {
                                        return (
                                            <div key={idx} style={{ height: 109, width: 96 }}>
                                                <CaptureThumb
                                                    showSettings={false}
                                                    src={val.thumb}
                                                    image={data}
                                                    resolvePath={true}
                                                    onImageEvent={() => {}}
                                                    // size={109}
                                                    // style={{ width: '96px', height: '109px', border: `1px solid transparent`, overflow: 'hidden', borderRadius: `20px !important` }}
                                                    selected={false}
                                                    onCaptureChange={() => {}}
                                                />
                                                {/* <ResolverImage width={100} height={100} src={val.thumb}  /> */}
                                            </div>
                                        );
                                    })}
                                </Group>
                                {/* <StyleMediaGridLayout thumbnailSize={50} guides={guides} filter={mediaFilter} captures={style?.assets ?? []} selectedItems={[]} onImageEvent={() => {}} onCaptureChange={() => {}} /> */}
                            </div>
                        </Box>
                    </Paper>
                </Grid.Col>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        style: state.styleReducer.selectedStyle,
        success: state.auth.success,
        error: state.auth.error,
        guides: state.guideReducer.guides,
    };
};

const mapDispatch = { selectStyle };

export default connect(mapStateToProps, mapDispatch)(StyleTabOverview);

export function StatsGridIcons({ data, onStatClick, isLoading }) {
    const { classes } = useStyles();
    const stats = data.map((stat) => {
        const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;
        return (
            <Paper shadow='md' className={classes.statPanel} withBorder p='md' radius='md' key={stat.title} onClick={() => onStatClick(stat)}>
                <Group position='apart'>
                    <div>
                        <Indicator position='middle-start' color={stat.color}>
                            <Text sx={{ paddingLeft: 12 }} color='dimmed' transform='uppercase' weight={700} size='xs' className={classes.label}>
                                {stat.title}
                            </Text>
                        </Indicator>

                        {isLoading ? (
                            <Skeleton height={8} my='lg' />
                        ) : (
                            <Text weight={700} size='xl'>
                                {stat.value}
                            </Text>
                        )}
                    </div>
                    {/* <ThemeIcon color='gray' variant='light' sx={(theme) => ({ color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6] })} size={38} radius='md'>
                        <DiffIcon size={28} stroke={1.5} />
                    </ThemeIcon> */}
                </Group>
            </Paper>
        );
    });

    return (
        <div className={classes.root}>
            <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {stats}
            </SimpleGrid>
        </div>
    );
}

const CustomTable = ({ columns, data, selectedItems, onItemClick, onItemSelected, isLoading, onMenuClick }) => {
    // Use the state and functions returned from useTable to build your UI

    // const [pagination, setPagination] = useState({
    //   pageIndex: 0,
    //   pageSize: 50,
    // })

    const [scrolled, setScrolled] = useState(false);

    const navigate = useNavigate();
    const { classes, cx } = useStyles();

    const {
        getTableProps,
        // getTableBodyProps,
        headerGroups,

        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        rows,
        // The rest of these things are super handy, too ;)
        // canPreviousPage,
        // canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        // preGlobalFilteredRows,
        // setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: AppConstants.defaultPaginationSize,
                globalFilter: null,
                // sortBy: [
                //     {
                //         id: columns[5].accessor,
                //         desc: true,
                //     },
                // ],
            },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
    );

    // Render the UI for your table
    return (
        <>
            <Table highlightOnHover size='sm' {...getTableProps()} sx={{ marginBottom: 0 }}>
                <thead className={cx('scrollTableHeader2', { ['scrolled']: scrolled })}>
                    {headerGroups.map((headerGroup) => {
                        const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <tr key={key} {...headerProps}>
                                {headerGroup.headers.map((column) => {
                                    const {key, ...hgProps} = column.getHeaderProps(column.getSortByToggleProps());
                                    return (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th key={key} {...hgProps} style={{ width: column.width }}>
                                            <Group position='apart'>
                                                <Text size='sm'>{column.render('Header')}</Text>
                                                {/* Add a sort direction indicator */}
                                                <Group>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                <IconChevronDown size={16} />
                                                            </ActionIcon>
                                                        ) : (
                                                            <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                <IconChevronUp size={16} />
                                                            </ActionIcon>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </Group>
                                            </Group>
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>

                {isLoading ? (
                    <tbody>
                        {[1, 2, 3].map((i) => (
                            <tr key={i}>
                                {columns.map((val, idx) => {
                                    return (
                                        <td key={idx}>
                                            <Skeleton height={8} my='lg' />
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();
                            return (
                                <tr key={key} {...rowProps}>
                                    {row.cells.map((cell) => {
                                        let renderCell;
                                        let checked = selectedItems.find((val) => val.id === row.original.id) ? true : false;
                                        switch (cell.column.id) {
                                            case 'sessionName': {
                                                renderCell = (
                                                    <Group sx={{ minWidth: '200px' }}>
                                                        <Group sx={{ flexShrink: 0 }}>
                                                            {/* <Checkbox
                                                                checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                                                onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked, rows)}></Checkbox> */}
                                                            {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}

                                                            <Box sx={{ flexShrink: 1 }} onClick={() => onItemClick(row.original)}>
                                                                <Anchor size='sm' title={row.original.name}>
                                                                    <Text size='sm' lineClamp={1} sx={{ maxWidth: '300px' }}>
                                                                        {row.original.sessionName}
                                                                    </Text>
                                                                </Anchor>
                                                                <Text size='xs' color='dimmed'>
                                                                    {row.original.ssPart}
                                                                </Text>
                                                            </Box>
                                                        </Group>
                                                    </Group>
                                                );
                                                break;
                                            }
                                            case 'seasonName': {
                                                renderCell = (
                                                    <Group sx={{ minWidth: '200px' }}>
                                                        <Group sx={{ flexShrink: 0 }}>
                                                            {/* <Checkbox
                                                                checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                                                onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked, rows)}></Checkbox> */}
                                                            {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}

                                                            <Box sx={{ flexShrink: 1 }} onClick={() => onItemClick(row.original)}>
                                                                <Anchor size='sm' title={row.original.name}>
                                                                    <Text size='sm' lineClamp={1} sx={{ maxWidth: '300px' }}>
                                                                        {row.original.seasonName}
                                                                    </Text>
                                                                </Anchor>
                                                                <Text size='xs' color='dimmed'>
                                                                    {row.original.seasonPart}
                                                                </Text>
                                                            </Box>
                                                        </Group>
                                                    </Group>
                                                );
                                                break;
                                            }
                                            case 'descr': {
                                                renderCell = (
                                                    <Text sx={{ maxWidth: 500 }} lineClamp={2} size='sm'>
                                                        {cell.render('Cell')}
                                                    </Text>
                                                );
                                                break;
                                            }
                                            case 'guideCode': {
                                                renderCell = (
                                                    <Text sx={{ maxWidth: 500 }} lineClamp={2} size='sm'>
                                                        {cell.render('Cell')} - {row.original.guideName}
                                                    </Text>
                                                );
                                                break;
                                            }
                                            case 'variation': {
                                                renderCell = (
                                                    <Text sx={{ maxWidth: 500 }} lineClamp={2} size='sm'>
                                                        {cell.render('Cell')} - {row.original.variationSize}
                                                    </Text>
                                                );
                                                break;
                                            }
                                            case 'shootDate':
                                            case 'shootDate':
                                            case 'deadline': {
                                                renderCell = (
                                                    <Text sx={{ maxWidth: 500 }} lineClamp={2} size='sm'>
                                                        {moment(row.original[cell.column.id]).format(DataFormats.DATE_FORMAT)}
                                                    </Text>
                                                );
                                                break;
                                            }
                                            case 'modified':
                                            case 'created': {
                                                let jsDate = moment(row.original[cell.column.id], 'YYYY-MM-DD-HH-mm-ss');
                                                renderCell = (
                                                    <Text sx={{ maxWidth: 500 }} lineClamp={2} size='sm'>
                                                        {moment(jsDate).format(DataFormats.DATE_FORMAT)}
                                                    </Text>
                                                );
                                                break;
                                            }
                                            case 'styleCount': {
                                                renderCell = row.original.styleCount ? <Badge>{cell.render('Cell')}</Badge> : '';
                                                break;
                                            }
                                            case 'productStatus':
                                            case 'status': {
                                                renderCell = <Badge color={getStatusColor(cell.value)}>{cell.render('Cell')}</Badge>;
                                                break;
                                            }

                                            case 'season': {
                                                renderCell = (
                                                    <Anchor
                                                        onClick={() => {
                                                            logger.log('click');
                                                            navigate(`/seasons/${row.original.seasonCode}`);
                                                        }}>
                                                        {cell.render('Cell')}
                                                    </Anchor>
                                                );
                                                break;
                                            }

                                            case 'team': {
                                                renderCell = (
                                                    <Group spacing={-10}>
                                                        {row.original.team.map((member, idx) => {
                                                            const userInitials = getUserInitials(member.name.split(' ')[0], member.name.split(' ')[1]);
                                                            const themeCol = getUserAvatarColour(userInitials);
                                                            const margin = idx ? -10 : 0;
                                                            return (
                                                                <Avatar
                                                                    key={idx}
                                                                    variant='filled'
                                                                    sx={{ marginLeft: margin }}
                                                                    title={`${member.name} - ${member.role}`}
                                                                    color={themeCol}
                                                                    size={30}
                                                                    radius={40}>
                                                                    {member.name.charAt(0)}
                                                                </Avatar>
                                                            );
                                                        })}
                                                    </Group>
                                                );
                                                break;
                                            }

                                            case 'metadata_toplevelcategory': {
                                                renderCell = (
                                                    <div onClick={() => onItemClick(row.original)}>
                                                        <Badge color='violet' sx={{ maxWidth: 150 }}>
                                                            {cell.render('Cell')}
                                                        </Badge>
                                                    </div>
                                                );
                                                break;
                                            }
                                            case 'action': {
                                                renderCell = row.canExpand ? null : (
                                                    <Group
                                                        sx={{
                                                            width: 40,
                                                            alignContent: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                        {/* <SessionItemMenu data={row.original} onMenuItemClick={(e) => onMenuClick(e, row.original)} /> */}
                                                    </Group>
                                                );
                                                break;
                                            }

                                            default: {
                                                renderCell = (
                                                    <>
                                                        <Text lineClamp={2} size='sm'>
                                                            {cell.render('Cell')}
                                                        </Text>
                                                    </>
                                                );
                                            }
                                        }
                                        const { key, ...cellProps } = cell.getCellProps();
                                        return (
                                            <td key={key} {...cellProps} className={checked ? 'cellChecked' : ''}>
                                                {renderCell}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </Table>

            {!isLoading && data.length === 0 ? <EmptyState showImage={false} style={{ padding: 20 }} /> : null}
        </>
    );
};
