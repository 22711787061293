import {
    Anchor,
    Box,
    Button,
    createStyles,
    Grid,
    Group,
    Select,
    Tabs,
    Text,
    Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
    clearNotifications,
    fetchGuide,
    fetchGuideCategories,
    selectGuide,
    updateGuide,
} from '../../guideSlice';
import { Crumbline, TabCounterLabel } from '../../../../core/components';
import GuideTabGeneral from './GuideTabGeneral';
import { useEffectOnce } from 'utils/helper';
import { logger } from 'utils/logger';
import GuideTabCategories from './GuideTabCategories';

const useStyles = createStyles((theme) => ({
    tab: {
        // marginLeft:`-${theme.spacing.lg}px`,
        // marginRight:`-${theme.spacing.lg}px`
    },
    col: {
        padding: 0,
    },
    tabContent: {
        padding: theme.spacing.xs,
        paddingTop: 0,
    },
}));

const Guide = ({ user, guide, success, error }) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    // const navigate = useNavigate();
    // const location = useLocation();
    const params = useParams();
    const [activeTab,setActiveTab] = useState('categories');
    const [isLoading, setIsLoading] = useState(false);

   
    // get the Guide from the url
    useEffectOnce(() => {
        if (params && params.id) {
            logger.log(params);
            dispatch(fetchGuide(params.id));
        }
    }, [dispatch, fetchGuide]);

    useEffectOnce(() => {
        // fetch the guide categories

        //  we no longer fetch the guide categories
        // if (guide && guide.code.length) {
        //     setIsLoading(true);
        //     dispatch(fetchGuideCategories(guide));
        // } else {
        //     setIsLoading(false);
        // }

        return () => {
            setIsLoading(false);
            dispatch(clearNotifications());
        };
    });

    const form = useForm({
        initialValues: {
            name:'',
            descr:'',
            tags:[],
        },

        validate: {
            name: (value) => value.length ? null : 'Please enter name',
            descr: (value) => value.length ? null : 'Please enter a description',
        },
    });

    useEffect(() => {

        logger.log('setting form')

        form.setValues({ ...guide });

        if (guide && !guide.guideCategories) {
            dispatch(fetchGuideCategories(guide));
        }

        if(guide){
            setIsLoading(false)
        }
    }, [guide]);

    const crumbItems = [
        { title: 'Style Guides', route: '/styleguides', search: '' },
        { title: guide?.name, route: `styleguides/${guide?.id}` },
    ];

    const onFormSubmit = (vals) => {
      logger.log('Submitting form');
      const data = {
          ...vals,
          tags: form.values.tags.join(','),
          // team:form.values.team,
          // ts:form.values.ts,
      };
      dispatch(updateGuide(data, user));
  };


    return (
        <div>
            <Group
                className='main-container-header'
                sx={(theme) => ({
                    width: '100%',
                    borderBottom: `1px solid ${theme.colors.gray[3]}`,
                    borderColor:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[6]
                            : theme.colors.gray[2],
                    backgroundColor:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[8]
                            : theme.white,
                })}>
                <Group px='lg' position='apart' sx={{ width: '100%' }}>
                    <Crumbline items={crumbItems}></Crumbline>
                    {/* <Button>Update Guide</Button> */}
                </Group>
            </Group>

            <Grid mb='lg' style={{ margin: 0 }}>
                <Grid.Col span={12} className={classes.col}>
                    <Title pt={'lg'} px={'lg'} order={3}>
                        {guide?.name}
                    </Title>
                    <Tabs
                        value={activeTab}
                        sx={(theme) => ({
                            // borderRight:`1px solid ${theme.colors.gray[2]}`,
                            '.mantine-Tabs-tabsList': {
                                paddingLeft: theme.spacing.lg,
                                paddingRight: theme.spacing.lg,
                                paddingTop: 29,
                            },
                            // borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                        })}
                        onTabChange={setActiveTab}>
                        <Tabs.List>
                            <Tabs.Tab value='general'>General</Tabs.Tab>
                            <Tabs.Tab value='categories'><TabCounterLabel label={'Categories'} data={guide} accessor={'guideCategories'} /></Tabs.Tab>
                            {/* <Tabs.Tab value="tasks" >Tasks</Tabs.Tab>
                    <Tabs.Tab value="media" >Media</Tabs.Tab>
                    <Tabs.Tab value="samples">Samples</Tabs.Tab>
                    <Tabs.Tab value="talent">Talent</Tabs.Tab> */}
                            <Tabs.Tab value='activity'>Activity</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value='general' pt='xs'>
                            <div className={classes.tabContent}>
                                <GuideTabGeneral form={form} onFormSubmit={onFormSubmit} />
                            </div>
                        </Tabs.Panel>
                        <Tabs.Panel value='categories' pt='xs'>
                            <div className={classes.tabContent}>
                                <GuideTabCategories form={form} onFormSubmit={onFormSubmit} />
                            </div>
                        </Tabs.Panel>

                        <Tabs.Panel value='tasks' pt='xs'>
                            <div className={classes.tabContent}>
                                {/* <GuideTabTasks form={form} /> */}
                            </div>
                        </Tabs.Panel>
                    </Tabs>
                </Grid.Col>

                {/* latest info */}
                {/* <Grid.Col span={3} className={classes.col}>
            <div className='right-detail-panel'>
              <Box sx={(theme)=>({
                  borderBottom:`2px solid ${theme.colors.gray[2]}`,  
                  borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],         
                })}>
              

                <Group py='xs' px='lg'>
                  <Text size='sm' weight='500'>Status</Text>
                  <Select style={{flexGrow:1}}
                    placeholder="Pick one"
                    data={[
                      { value: 'delayed', label: 'Delayed' },
                      { value: 'onTime', label: 'On Time' },
                      { value: 'archived', label: 'Archived' },
                      { value: 'available', label: 'Available' },
                    ]}
                  />
                </Group>
              </Box>

              <Box px='lg' pt='lg'>

                <Title className='main-content-heading' order={5} mb='lg'>Details</Title>
                
                <Box>
                  <Group my='md'>
                    <Text size='sm' style={{width:'30%'}} weight='500'>Name</Text>
                    <div>
                      <Text size='sm'>John Adams</Text>  
                    </div>                    
                  </Group>

                  <Group my='md'>
                    <Text size='sm' style={{width:'30%'}} weight='500'> Time</Text>
                    <div>
                      <Text size='sm'>30/05/2022 - 9:00 AM</Text>  
                    </div>                    
                  </Group>

                  <Group my='md'>
                    <Text size='sm' style={{width:'30%'}} weight='500'>Location</Text>
                    <div>
                      <Anchor size='sm'>Wellcom Lorimer Street</Anchor>  
                    </div>                    
                  </Group>
                  <Button variant='light' color="brand" fullWidth style={{ marginTop: 14 }}>
                    View Scan Details
                  </Button>

                </Box>

              </Box>

            </div>
          
          </Grid.Col> */}
            </Grid>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        guide: state.guideReducer.selectedGuide,
        success: state.guideReducer.success,
        error: state.guideReducer.error,
    };
};

const mapDispatch = { selectGuide };

export default connect(mapStateToProps, mapDispatch)(Guide);
