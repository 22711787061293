import { Accordion, ActionIcon, Badge, Box, Button, Grid, Group, Skeleton, Stack, Text, TextInput, ThemeIcon, Title, Tooltip, createStyles, useMantineTheme } from '@mantine/core';
import { connect, useDispatch } from 'react-redux';
import { selectSession } from 'modules/Sessions/sessionSlice';
import { IconCategory, IconChevronDown, IconGripVertical, IconSearch, IconX } from '@tabler/icons';
import { logger } from 'utils/logger';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { pluralize } from 'utils/helper';
import { getGuideSuccess, selectGuide, selectGuideCategory, updateGuide } from 'modules/Guides/guideSlice';
import { GuideCategoryModel } from 'core/models';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import CategoryEdit from './CategoryEdit';
import DivButton from 'core/components/divButton/DivButton';
import { CategoryShotModel } from 'core/models/CategoryShotModel';
import { GuideActions } from 'utils/constants';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

const useStyles = createStyles((theme) => ({
    accordianLoader: {
        border: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
        padding: theme.spacing.md,
    },
    accordian: {
        border: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[5]}`,
        borderTop: 0,
        '&:first-of-type': {
            borderTop: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[5]}`,
        },
    },
    accordianPanel: {
        transition: 'all .5s',
        '.mantine-Paper-root': {
            padding: theme.spacing.md,
        },
    },
}));

const GuideTabCategories = ({ user, guide, success, error, form, onFormSubmit, selectGuideCategory }) => {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAccorian, setSelectedAccordian] = useState('');
    const [data, setData] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const theme = useMantineTheme();
    
    useEffect(() => {
        logger.log(success);
        // if (success && success.type === getGuideCategoriesSuccess.toString()) {
        //     setIsLoading(false);
        // }
        if (success && success.type === getGuideSuccess.toString()) {
            setIsLoading(false);
        }
    }, [success]);

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        setActiveId(null);

        if (active.id !== over.id) {
           
            const oldIndex = data.findIndex((val) => val.id === active.id);
            const newIndex = data.findIndex((val) => val.id === over.id);
            
            // move the items and update their sort index
            var newData = arrayMove([...data], oldIndex, newIndex);
            newData = newData.map((val, idx) => ({ ...val, sort: idx }));
            setData(newData);

            // update the guide to save the positions
            const payload = { ...guide, guideCategories: newData };

            // update the data
            dispatch(updateGuide(payload));
            
            // if the accordian is open we open at the correct index
            if(selectedAccorian)
                setSelectedAccordian(newIndex.toString())

        }
    }

    const onEditCateogryClick = (category, type) => {
        switch (type) {
            // switch to edit mode for that category
            case 'edit': {
                logger.log(category);
                dispatch(selectGuideCategory({ ...category }));

                // if the map exists we update the data
                if (editMapRef.current[category.id]) {
                    dispatch(updateGuide(guide));
                }

                editMapRef.current[category.id] = !editMapRef.current[category.id];

                break;
            }
            case GuideActions.ADD_GUIDE_CAT_SHOT: {
                const seq = category.shots ? category.shots.length + 1 : 1;
                const tmpShots = category.shots ? category.shots : [];
                const shot = new CategoryShotModel({ seq: seq.toString() });

                const shots = [...tmpShots, shot];
                const tmpCat = { ...category, shots: shots };

                const tmpCats = guide.guideCategories.map((val) => {
                    if (val.id === tmpCat.id) {
                        return tmpCat;
                    } else {
                        return val;
                    }
                });

                dispatch(selectGuide({ ...guide, guideCategories: tmpCats }));
                // dispatch(selectGuideCategory(tmpCat));
                break;
            }

            case GuideActions.REMOVE_GUIDE_CAT: {
                openConfirmModal({
                    title: 'Confirm Delete',
                    children: <Text size='sm'>Are you sure you want to remove this category?. All shots assigned to this category will also be removed.</Text>,
                    labels: { confirm: 'Confirm', cancel: 'Cancel' },
                    onCancel: () => console.log('Cancel'),
                    onConfirm: () => {
                        const tmpCats = guide.guideCategories.filter((val) => {
                            if (val.id !== category.id) {
                                return val;
                            }
                        });

                        const payload = { ...guide, guideCategories: tmpCats };
                        dispatch(selectGuide(payload));

                        // update the data
                        dispatch(updateGuide(payload));
                    },
                });
                break;
            }

            case GuideActions.UPDATE_GUIDE_CAT: {
                const tmpCats = guide.guideCategories.map((val) => {
                    if (val.id === category.id) {
                        return category;
                    } else {
                        return val;
                    }
                });

                dispatch(selectGuide({ ...guide, guideCategories: tmpCats }));
                break;
            }

            default:{
                break;
            }
        }
    };

    const onCategoryChange = (cat) => {
        logger.log('changed');
        const tmpCats = guide.guideCategories.map((val) => {
            if (val.id === cat.id) {
                return cat;
            } else {
                return val;
            }
        });
        dispatch(selectGuide({ ...guide, guideCategories: tmpCats }));
    };
    const openGuideCategory = () => {
        openModal({
            title: 'Create a new Guide Category',
            size: '80%',
            children: (
                <>
                    <CategoryEdit onCancel={closeAllModals} />
                </>
            ),
        });
    };

    const editMapRef = useRef({});
    const items = data?.map((item) => item.id);
    const loaderRows = [1, 2, 3].map((val) => (
        <Group key={val} position='apart' className={classes.accordianLoader}>
            <Skeleton height={26} radius='xl' width={200} />

            <Group>
                <Skeleton height={26} radius='xl' width={100} />
                <Skeleton height={26} radius='xl' width={50} />
            </Group>
        </Group>
    ));

    const [mediaFilter, setMediaFilter] = useState({ keyword: '' });
    // const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const onCreateClick = () => {
        // create a new season
        var tmpCat = new GuideCategoryModel();

        logger.log(tmpCat);
        dispatch(selectGuideCategory({ ...tmpCat, name: '', sort: guide.guideCategories.length.toString() }));
        // selectGuideCategory(tmpCat);

        openGuideCategory();
        // setSeasonModalOpen(true);
    };

    const filterMedia = useCallback(() => {
        logger.log('FILTERING MEDIA');

        if (guide && guide.guideCategories) {
            const newData = guide.guideCategories.filter((cat) => {
                var match = true;
                if (cat && mediaFilter.keyword && mediaFilter.keyword.length && match) {
                    match = cat.name.toLowerCase().indexOf(mediaFilter.keyword.toLowerCase()) > -1 ? true : false;
                }
                return match;
            });
            setData([...newData]);
        }
    }, [mediaFilter, setData, guide]);

    useEffect(() => {
        filterMedia();
    }, [mediaFilter, guide]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <div style={{ height: '100%' }}>
            <Box px='xl' sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} pt='xl' pb={'sm'} mb={'sm'}>
                    <Group>
                        {/* <CheckboxDropdown  /> */}

                        <TextInput
                            icon={<IconSearch />}
                            sx={{ width: 250 }}
                            value={mediaFilter.keyword}
                            onChange={(e) =>
                                setMediaFilter({
                                    ...mediaFilter,
                                    keyword: e.currentTarget.value,
                                })
                            }
                            placeholder={`Search across ${guide?.guideCategories?.length} Categories`}
                            rightSection={<ActionIcon onClick={() => setMediaFilter({ ...mediaFilter, keyword: '' })}>{mediaFilter.keyword.length ? <IconX size={14} /> : null}</ActionIcon>}
                        />

                        <Text size='sm'>
                            Total{' '}
                            <strong>
                                {guide?.guideCategories?.length} of {guide?.guideCategories?.length}
                            </strong>{' '}
                        </Text>
                    </Group>

                    <Group>
                        <Button onClick={() => onCreateClick()}>Add Category</Button>
                    </Group>
                </Group>
            </Box>
            <div sx={{ marginLeft: 0 }}>
                <Grid>
                    <Grid.Col span={12}>
                        <Box px='lg'>
                            {guide && guide.code && guide.code.length ? (
                                <>
                                    <Stack mb={'lg'} spacing={0}>
                                        {isLoading ? (
                                            <>{loaderRows}</>
                                        ) : (
                                            <>
                                                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                                    <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                                        {/* <Box> */}
                                                        <Accordion chevronPosition='right' variant='contained' value={selectedAccorian} onChange={setSelectedAccordian}>
                                                            {/* {items} */}
                                                            {items.map((item, idx) => (
                                                                <SortableItemGroup
                                                                    id={item}
                                                                    item={data[idx]}
                                                                    idx={idx}
                                                                    onCategoryChange={onCategoryChange}
                                                                    editMapRef={editMapRef}
                                                                    onEditCateogryClick={onEditCateogryClick}
                                                                    key={idx}
                                                                />
                                                            ))}
                                                            {/* </Box> */}
                                                        </Accordion>
                                                        {/* <div>
                                                            {items.map((item, idx) => (
                                                                <SortableItem id={item} idx={idx} key={idx} />
                                                            ))}
                                                        </div> */}
                                                        {!guide.guideCategories?.length && !isLoading ? (
                                                            <EmptyState
                                                                style={{
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                }}
                                                                showImage={true}
                                                                caption='There are no Guide Categories'
                                                            />
                                                        ) : null}
                                                    </SortableContext>

                                                    <DragOverlay>
                                                        <div style={{background:theme.colors.dark[5], borderRadius:theme.radius.md, overflow:'hidden'}}>
                                                        <SortableItemGroup
                                                            id={activeId}
                                                            item={data.find(val => val.id ===activeId)}
                                                            idx={activeId}
                                                            onCategoryChange={onCategoryChange}
                                                            editMapRef={editMapRef}
                                                            onEditCateogryClick={onEditCateogryClick}
                                                            useAccordianControl={false}
                                                            key={activeId}
                                                        />
                                                        </div>
                                                    </DragOverlay>

                                                </DndContext>
                                            </>
                                        )}

                                        {/* {(!guide.guideCategories || (guide.guideCategories && guide.guideCategories.length === 0) )?
                                        <EmptyState />
                                        :null} */}
                                    </Stack>
                                </>
                            ) : null}
                            {/* {!isLoading ? (
                                <>{loaderRows}</>
                            ) : (
                                <Stack spacing={'xl'}>
                                    {guide?.guideCategories?.map((val, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <Category value={val} classes={classes} onEditClick={onEditCateogryClick} />
                                            </React.Fragment>
                                        );
                                    })}
                                </Stack>
                            )} */}
                        </Box>
                    </Grid.Col>
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        guide: state.guideReducer.selectedGuide,
        category: state.guideReducer.selectedGuideCategory,
        success: state.guideReducer.success,
        error: state.guideReducer.error,
    };
};

const mapDispatch = { selectSession, selectGuideCategory };

export default connect(mapStateToProps, mapDispatch)(GuideTabCategories);


function SortableItemGroup(props) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
    const { item, idx, onCategoryChange, editMapRef, onEditCateogryClick, useAccordianControl = true } = props;    
    const [isOpen, setIsOpen] = useState(false);
    const { classes } = useStyles();

    function AccordionLabel(props) {
        const { name, id = '', shots, onAction } = props;
        const [value, setValue] = useState(name);
        return (
            <Group noWrap grow sx={{ flexGrow: 1 }}>
                <Group position='apart'>
                    <Group>
                        <IconCategory stroke={1} />

                        {/* If we're editing show a text input */}
                        {editMapRef.current[id] ? (
                            <TextInput
                                data-autofocus
                                value={value}
                                size='sm'
                                variant='filled'
                                radius={'md'}
                                sx={(theme) => ({ width: 300, fontWeight: 'bold' })}
                                onChange={(e) => setValue(e.currentTarget.value)}
                                onBlur={(e) => onAction(GuideActions.UPDATE_GUIDE_CAT, { ...props, name: e.currentTarget.value })}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 32 || e.key === ' ') {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        let val = value + ' ';
                                        setValue(val);
                                    }
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}></TextInput>
                        ) : (
                            <Title order={6}>{name}</Title>
                        )}
                    </Group>
                    {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>{shots.length} Shots</Badge> */}
                    <Group>
                        <Badge>
                            {shots?.length} {pluralize('Shot', shots?.length)}
                        </Badge>
                        {editMapRef.current[id] ? (
                            <Group>
                                <DivButton
                                    color='brand'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onAction(GuideActions.ADD_GUIDE_CAT_SHOT, props);
                                    }}>
                                    Add Shot
                                </DivButton>
                            </Group>
                        ) : null}

                        <Group>
                            <DivButton
                                color='red'
                                variant='light'
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onAction(GuideActions.REMOVE_GUIDE_CAT, props);
                                }}>
                                Delete
                            </DivButton>
                            <DivButton
                                color='brand'
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onAction('edit', props);
                                }}>
                                {editMapRef.current[id] ? 'Done Editing' : 'Edit'}
                            </DivButton>
                        </Group>
                    </Group>
                </Group>
            </Group>
        );
    }
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const onLabelClick = (e) => {
        setIsOpen(!isOpen);
    };

    if (useAccordianControl) {
        return (
            <Accordion.Item value={idx.toString()} key={idx} ref={setNodeRef} style={style}>
                <Accordion.Control>
                    <Tooltip label='Drag to sort'>
                        <ThemeIcon
                            size='xs'
                            variant='outline'
                            {...listeners}
                            {...attributes}
                            sx={{
                                border:0,
                                position: 'relative',
                                width: 30,
                                zIndex: 999,
                                borderBottomRightRadius: 0,
                                // background: 'transparent',
                                ':hover': { backgroundColor: 'transparent', cursor: 'move' },
                                float: 'left',
                                height: 36,
                                marginRight: 15,
                            }}>
                            <IconGripVertical size={14} color='white' />
                        </ThemeIcon>
                    </Tooltip>

                    <AccordionLabel {...item} onAction={(type, value) => onEditCateogryClick(value, type)} />
                </Accordion.Control>
                <Accordion.Panel>
                    <Box>
                        {' '}
                        <CategoryEdit onChange={onCategoryChange} value={item} editable={editMapRef.current[item.id] ? true : false} onCancel={closeAllModals} showActionBar={false} />
                    </Box>
                </Accordion.Panel>
            </Accordion.Item>
        );
    } else {
        return (
            <Box value={idx.toString()} key={idx} ref={setNodeRef} className={classes.accordian} style={style} p={0}>
                <Group py={'md'} pl={'md'} pr={7}>
                    
                        <ActionIcon
                            size='xs'
                            variant='light'
                            {...listeners}
                            {...attributes}
                            sx={{
                                position: 'relative',
                                width: 30,
                                zIndex: 999,
                                borderBottomRightRadius: 0,
                                // background: 'transparent',
                                ':hover': { backgroundColor: 'transparent', cursor: 'move' },
                                float: 'left',
                                height: 30,
                                marginRight: 0,
                            }}>
                            <IconGripVertical size={14} color='white' />
                        </ActionIcon>
                    
                    <div style={{ flexGrow: 1 }} onClick={onLabelClick}>
                        <AccordionLabel {...item} onAction={(type, value) => onEditCateogryClick(value, type)} />
                    </div>
                    <ThemeIcon variant='outline' sx={{border:0, padding:0}}>
                        <IconChevronDown size={16} />
                    </ThemeIcon>
                </Group>
                <div className={classes.accordianPanel} style={{ maxHeight: isOpen ? 5000 : 0, overflow: 'hidden' }}>
                    <Box>
                        {' '}
                        <CategoryEdit onChange={onCategoryChange} value={item} editable={editMapRef.current[item.id] ? true : false} onCancel={closeAllModals} showActionBar={false} />
                    </Box>
                </div>
            </Box>
        );
    }
}
