import { Accordion, ActionIcon, Anchor, Badge, Box, Card, Center, Checkbox, Divider, Group, Menu, Skeleton, Text, Title } from '@mantine/core';
import { memo, useCallback, useMemo, useRef } from 'react';

import { FixedSizeGrid } from 'react-window';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { IconArchive, IconArchiveOff, IconCamera, IconCategory, IconDots } from '@tabler/icons';
import { CaptureThumb } from 'core/components/index';
import { logger } from 'utils/logger';
import { useLocation, useNavigate } from 'react-router-dom';
import { setPreviousRoute } from 'app/appSlice';
import { getGuideInfo, pluralize } from 'utils/helper';
import { StyleAssetStatusTypes } from 'core/models/StyleAssetFilterModel';
import _ from 'lodash';
import { CaptureEventType, CaptureStatusTypes } from 'core/components/capture/CaptureThumb';
import ResolverImage from 'core/components/resolverImage/ResolverImage';

const StyleMediaGridLayout = ({ onImageEvent, thumbnailSize = 200, captures = [], selectedItems, onCaptureChange, showLoading, filter, guides, onGroupSelect, onItemMenuClick }) => {
    // const imageHash = useRef({})
    logger.log(captures, showLoading);
    const location = useLocation();
    const navigate = useNavigate();
    const containerRef = useRef();

    const isSelected = useCallback((val, selectedItems) => {
        let selected = false;
        if (selectedItems && val) {
            selected = selectedItems.find((item) => val.id === item.id) ? true : false;
        }
        return selected;
    }, []);

    function isEqual() {
        return false;
    }

    const ProxyCell = memo((props) => {
        const { columnIndex, rowIndex, style } = props;

        const ThumbCallback = useCallback(({ columnIndex, data, rowIndex, style }) => {
            return (
                <div style={{ height: '100%', width: '100%', padding: 5 }}>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Skeleton width={'100%'} height={'80%'} />
                        <Skeleton height={8} mt={10} radius='xl' />
                        <Skeleton height={8} mt={5} width='70%' radius='xl' />
                    </Box>
                </div>
            );
        }, []);

        const tmpStyle = { ...style };
        return (
            <div key={`${columnIndex}-${rowIndex}`} style={tmpStyle}>
                <ThumbCallback {...props} />
            </div>
        );
    }, isEqual);

    const Cell = memo(
        (props) => {
            const { columnIndex, data, rowIndex, style } = props;
            const idx = columnIndex + rowIndex * data.colCount;

            const items = data.collection ? data.collection : captures;
            var val = items[idx];

            if (!data.colCount) {
                val = items.find((val) => val.id === data.id);
                val = val.original;

                if (!val) {
                    val = items[idx];
                }
            } else if (val) {
                val = val.original;
                if (!val) {
                    val = items[idx];
                }
            }

            let guideInfo = getGuideInfo(val?.guideID, val?.catId, guides);

            const ThumbCallback = useCallback(
                ({ columnIndex, data, rowIndex, style }) => {
                    var imageURL = data && data.preview ? data.preview : '';

                    const onAssetMenuClick = (e) => {
                        onItemMenuClick(e);
                    };
                    return (
                        <div style={{ height: '100%', width: '100%' }}>
                            {data ? (
                                <Card mx={'xs'} sx={(theme) => ({ background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0] })}>
                                    <Card.Section
                                        sx={(theme) => ({
                                            height: 290,
                                            marginTop: `-26px !important`,
                                            backgroundImage: `url(${imageURL})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            // background: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[2]
                                        })}>
                                        <ResolverImage fit={'contain'} height={290} src={imageURL} />
                                        {/* <CaptureThumb
                                            showSettings={false}
                                            src={imageURL}
                                            image={data}
                                            resolvePath={true}
                                            onImageEvent={onImageEvent}
                                            size={250}
                                            selected={isSelected(val, selectedItems)}
                                            // onCaptureChange={(type, val) => onCaptureChange(type, val, [data])}
                                        /> */}
                                    </Card.Section>
                                    <Group position='apart' noWrap mt='md' mb='xs' align='start'>
                                        <Group spacing={'sm'}>
                                            <Text weight={500} size={'sm'} truncate>
                                                {data.originalFileName}
                                            </Text>
                                            <Badge color={data.viewstatus === StyleAssetStatusTypes.ARCHIVED ? 'gray' : 'teal'}>
                                                {data.viewstatus && data.viewstatus.length ? data.viewstatus : 'ACTIVE'}
                                            </Badge>
                                        </Group>
                                        <div>
                                            <Menu shadow='md' width={200}>
                                                <Menu.Target>
                                                    <ActionIcon>
                                                        <IconDots />
                                                    </ActionIcon>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    {/* <Menu.Label>Application</Menu.Label> */}
                                                    {data.viewstatus === CaptureStatusTypes.ACTIVE ? (
                                                        <Menu.Item
                                                            onClick={(e) => {
                                                                if (onAssetMenuClick) {
                                                                    onAssetMenuClick({ asset: data, type: CaptureEventType.ARCHIVE });
                                                                }
                                                            }}
                                                            icon={<IconArchive size={14} />}>
                                                            Archive
                                                        </Menu.Item>
                                                    ) : null}
                                                    {data.viewstatus === CaptureStatusTypes.ARCHIVED ? (
                                                        <Menu.Item
                                                            onClick={(e) => {
                                                                if (onAssetMenuClick) {
                                                                    onAssetMenuClick({ asset: data, type: CaptureEventType.UNARCHIVE });
                                                                }
                                                            }}
                                                            icon={<IconArchiveOff size={14} />}>
                                                            Unarchive
                                                        </Menu.Item>
                                                    ) : null}

                                                    {/* <Menu.Item icon={<IconTransferIn size={14} />}>Assign to session</Menu.Item>         */}
                                                </Menu.Dropdown>
                                            </Menu>
                                            {/* <Badge color='green' variant='light'>
                                                {data.status}
                                            </Badge> */}
                                        </div>
                                    </Group>
                                    <Divider />
                                    <Card.Section py='md' mx={0}>
                                        <Text size='xs' c='dimmed'>
                                            Style Guide
                                        </Text>

                                        <Group>
                                            <Anchor
                                                title='Show guide'
                                                size='sm'
                                                onClick={() => {
                                                    const tmpLoc = `${location.pathname}/${location.search}`;
                                                    setPreviousRoute(tmpLoc);
                                                    navigate(`/styleguides/${data.guideID}`);
                                                }}>
                                                <Text size={'sm'}>{guideInfo.guide?.name}</Text>
                                            </Anchor>
                                        </Group>

                                        <Text mt={'md'} size='xs' c='dimmed'>
                                            Category
                                        </Text>

                                        <Group>
                                            <Text size={'sm'}>{guideInfo.category?.name}</Text>
                                        </Group>

                                        <Text mt={'md'} size='xs' c='dimmed'>
                                            Season
                                        </Text>

                                        <Group>
                                            <Anchor
                                                title='Event'
                                                size='sm'
                                                onClick={() => {
                                                    const tmpLoc = `${location.pathname}/${location.search}`;
                                                    setPreviousRoute(tmpLoc);
                                                    navigate(`/seasons/${data.seasonCode}`);
                                                }}>
                                                <Text size={'sm'}>{data.seasonCode}</Text>
                                            </Anchor>
                                        </Group>

                                        <Text mt={'md'} size='xs' c='dimmed'>
                                            Session
                                        </Text>

                                        <Group>
                                            <Anchor
                                                title='Session'
                                                size='sm'
                                                onClick={() => {
                                                    const tmpLoc = `${location.pathname}/${location.search}`;
                                                    setPreviousRoute(tmpLoc);
                                                    navigate(`/session/${data.sessionCode}`);
                                                }}>
                                                <Text size={'sm'}>{data.sessionCode}</Text>
                                            </Anchor>
                                        </Group>
                                    </Card.Section>
                                </Card>
                            ) : (
                                // <ResolverImage src={imageURL} />
                                <div></div>
                            )}
                        </div>
                    );
                },
                [val],
            );

            const tmpStyle = { ...style };
            return (
                <div key={`${columnIndex}-${rowIndex}`} style={tmpStyle}>
                    <ThumbCallback {...props} data={val} />

                    {/* <ResolverImage fit={'contain'} height={200} src={val?.thumb} /> */}
                </div>
            );
        },

        isEqual,
    );
    // const getLayout_table = () => {
    //     return (
    //         <Grid>
    //             {captures.map((val, idx) => {
    //                 return (
    //                     <Grid.Col key={idx} md={6} lg={2}>
    //                         <Cell data={val} style={{ height: '100%', width: '100%' }} />
    //                     </Grid.Col>
    //                 );
    //             })}
    //         </Grid>
    //     );
    // };

    function AccordionLabel({ original, image, descr, subRows }) {
        var isChecked = false;
        var indeterminate = false;
        if (original.subRows) {
            // if all of the subrows are included in the selected items.`
            const commonItems = _.intersectionBy(original.subRows, selectedItems, 'id');
            isChecked = commonItems.length === original.subRows.length;
            indeterminate = commonItems.length && commonItems.length < original.subRows.length ? true : false;
        }

        return (
            <Group noWrap grow>
                <Group position='apart'>
                    <Group noWrap>
                        <Checkbox
                            indeterminate={indeterminate}
                            checked={isChecked}
                            // checked={selectedGroups.find((val) => val.id === original.id) ? true : false}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onChange={(e) => {
                                onGroupSelect(e, original, e.currentTarget.checked);
                            }}></Checkbox>
                        <IconCategory stroke={1} />
                        <Group>
                            <Title order={5}>{original.name}</Title>
                        </Group>
                    </Group>

                    {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>{shots.length} Shots</Badge> */}
                    <Badge>
                        {subRows.length} {pluralize('Asset', subRows.length)}
                    </Badge>
                    {/* <Text size="sm" color="dimmed" weight={400}>
              {shots.length}
          </Text> */}
                </Group>
            </Group>
        );
    }

    const getLayout = () => {
        logger.log(filter, captures);
        const seenIds = new Set();
        if (filter.groupBy) {
            return (
                <>
                    <Accordion variant='contained' chevronPosition='left'>
                        {captures
                            .filter((item, idx) => {
                                const itemId = item.id.split('.')[0];
                                if (itemId !== undefined && !seenIds.has(itemId)) {
                                    seenIds.add(itemId);
                                    return true;
                                }
                                return false;
                            })
                            .map((item, idx) => {
                                const colWidth = thumbnailSize < 180 ? 190 : thumbnailSize + 40;
                                const rowHeight = thumbnailSize < 180 ? 260 : thumbnailSize + 280;

                                const panels = document.getElementsByClassName('body-panels')[0];
                                let width = panels ? panels.offsetWidth - 120 : 500;
                                let colCount = Math.floor(width / colWidth);
                                let rowCount = showLoading ? 4 : Math.floor(item.subRows.length / colCount);

                                if ((item.subRows.length / colCount) % 2) {
                                    ++rowCount;
                                    // ++colCount;
                                }

                                const height = rowCount * rowHeight;
                                logger.log(item);
                                return (
                                    <Accordion.Item value={item.id} key={idx}>
                                        <Accordion.Control>
                                            <AccordionLabel {...item} />
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            <FixedSizeGrid
                                                columnCount={colCount}
                                                columnWidth={width / colCount}
                                                // columnWidth={index => columnWidths[index]}
                                                height={height}
                                                rowCount={rowCount}
                                                rowHeight={rowHeight}
                                                width={width + 25}
                                                itemData={{ colCount: colCount, rowCount: rowCount, collection: item.subRows, onAssetMenuClick: onItemMenuClick }}>
                                                {Cell}
                                            </FixedSizeGrid>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                );
                            })}
                    </Accordion>
                </>
            );
        } else {
            return (
                <ReactVirtualizedAutoSizer>
                    {({ height, width }) => {
                        const colWidth = thumbnailSize < 180 ? 190 : thumbnailSize + 40;
                        const rowHeight = thumbnailSize < 180 ? 260 : thumbnailSize + 400;

                        let colCount = Math.floor(width / colWidth);
                        let rowCount = showLoading ? 4 : Math.floor(captures.length / colCount);

                        if ((captures.length / colCount) % 2) {
                            ++rowCount;
                            // ++colCount;
                        }
                        //rowCount = (captures.length/colCount)%2?rowCount+1:rowCount;

                        return (
                            <>
                                {showLoading ? (
                                    <FixedSizeGrid
                                        columnCount={colCount}
                                        columnWidth={width / colCount}
                                        height={height}
                                        rowCount={rowCount}
                                        rowHeight={rowHeight}
                                        width={width}
                                        itemData={{ colCount, rowCount }}>
                                        {ProxyCell}
                                    </FixedSizeGrid>
                                ) : (
                                    <FixedSizeGrid
                                        columnCount={colCount}
                                        columnWidth={width / colCount}
                                        height={height}
                                        rowCount={rowCount}
                                        rowHeight={rowHeight}
                                        width={width + 25}
                                        itemData={{ colCount, rowCount }}>
                                        {Cell}
                                    </FixedSizeGrid>
                                )}
                            </>
                        );
                    }}
                </ReactVirtualizedAutoSizer>
            );
        }
    };

    const content = !showLoading && captures.length === 0 ? <EmptyState /> : getLayout();
    return (
        // <Group spacing={'lg'}>

        //     {captures.map((val,idx) => (
        //         <ImageThumb key={idx} image={val} onImageEvent={onImageEvent} size={thumbnailSize} selected={isSelected(val,selectedItems)} />
        //     ))}

        // </Group>
        <Box
            ref={containerRef}
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                // marginRight: -theme.spacing.lg,
                marginLeft: 0,
                paddingLeft: theme.spacing.xl,
                paddingRight: theme.spacing.xl,
            })}>
            {content}
        </Box>
    );
};

export default StyleMediaGridLayout;

const EmptyState = () => {
    return (
        <Center sx={{ height: '100%' }}>
            <div style={{ textAlign: 'center' }}>
                <IconCamera size={48} stroke={1} />
                <Title color={'dimmed'} order={3}>
                    No Assets Available
                </Title>
                <Text color={'dimmed'}>Try changing the Catalogue or Filter</Text>
            </div>
        </Center>
    );
};
