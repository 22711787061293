import { SampleModel } from "core/models";
import { getToken, parseValue } from "utils/helper";
import { logger } from "utils/logger";

export const getSample = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/warehouse/feed?style=${id}`;

  const formData = JSON.stringify({

  })

  logger.log(" GETTING SAMPLE DATA: ", formData);
  return fetch(url, {
    headers: {
      'hgio': getToken(),
      // 'Content-Type': 'application/json'
    },
    method: 'GET',
    // body: formData,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    var items = data.Items.map((item, idx) => {

      
      var newItem = new SampleModel(parseValue(item));
      newItem.id = newItem.sampleId;
      newItem.descr = newItem.descr.length ? newItem.descr : newItem.description;
      newItem.departmentStr = `${newItem.Dept} - ${newItem.department}`;
      return newItem
    })


    return items[0];
    //return imgUploadUrl;
  })
    .catch((err) => {
      logger.error('ERROR getting sample', err);
      throw (err);
    })
}



export const getSamples = async (criteria, token) => {
  var url = `${process.env.REACT_APP_API_URL}`;

  if (criteria && criteria.sessionId && criteria.sessionId.length) {
    url += `/session/${criteria.sessionId}/style`;
  }
  else {
    url += `/session`;
  }

  const formData = JSON.stringify({

  })

  logger.log(" GETTING SAMPLES DATA: ", formData);
  return fetch(url, {
    headers: {
      'hgio': getToken(),
      'Content-Type': 'application/json'
    },
    method: 'GET',
    // body: formData,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    logger.log(data)
    var items = data.Items.map((item, idx) => {
      const keys = Object.keys(item);
      
      var newItem = new SampleModel(parseValue(item));
      newItem.id = newItem.sampleId;
      newItem.descr = newItem.description ? newItem.description : newItem.descr;
      newItem.departmentStr = `${newItem.Dept} - ${newItem.department}`;

      if(newItem.departmentStr.trim() === '-'){
        newItem.departmentStr = '';
      }
      
      if (item.captures && item.captures["L"]) {
        newItem.captures = item.captures["L"].map(val => {
          return { id: val["S"] };
        })
      } else {
        newItem.captures = [];
      }

      return newItem
    })

    //items = items.splice(0,50)
    logger.log(items, ' getsamples')
    return items
    //return imgUploadUrl;
  })
    .catch((err) => {
      console.error('ERROR getting samples', err);
      throw (err);
    })
}

export const getFeed = async (criteria, token) => {
  var url = `${process.env.REACT_APP_API_URL}`;

  if (criteria && criteria.styleId && criteria.styleId.length) {
    url += `/warehouse/feed?style=${criteria.styleId}`;
  }
  else {
    url += `/warehouse/feed`;
  }

  const formData = JSON.stringify({

  })

  logger.log(" GETTING SAMPLES DATA: ", formData);
  return fetch(url, {
    headers: {
      'hgio': getToken(),
      'Content-Type': 'application/json'
    },
    method: 'GET',
    // body: formData,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    logger.log(data)
    var items = data.Items.map((item, idx) => {
      const keys = Object.keys(item);
      var newItem = {};
      newItem = new SampleModel(parseValue(item));
      newItem.id = newItem.sampleId;
      newItem.descr = newItem.description ? newItem.description : newItem.descr;
      newItem.stylenumber = newItem.targetSort;
      return newItem
    })

    //items = items.splice(0,50)
    logger.log(items, ' getsamples')
    return items
    //return imgUploadUrl;
  })
    .catch((err) => {
      console.error('ERROR getting samples', err);
      throw (err);
    })
}

export const updateSample = async (season, sample) => {
  let url = sample.id ? `${process.env.REACT_APP_API_URL}/seasons/${season.id}/style/${sample.styleCode}` : `${process.env.REACT_APP_API_URL}/seasons/${season.id}/style/${sample.styleCode}`;
  let tmpData = { ...sample };

  let formData = JSON.stringify({
    ...tmpData,
  });

  logger.log(' UPDATING Sample DATA: ', formData);

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      hgio: getToken(),
    },
    method: sample.id ?'POST':'PUT',
    body: formData,
  })
    .then((response) => {
      // return response.json();
      return true;
    })
    // .then((data) => {
    //   logger.log(data);
    //   if (data.__type) {
    //     throw (new Error('ERROR updating sample'))
    //   }
    //   return data;
    //   //return imgUploadUrl;
    // })
    .catch((err) => {
      logger.error('ERROR updating sample', err);
      throw err;
    });
};



export const getSessionLiveData = async (id) => {
  var url = `${process.env.REACT_APP_API_URL}/session/${id}/livedata`;

  // logger.log(" GETTING SAMPLES DATA: ", formData);
  return fetch(url, {
    headers: {
      'hgio': getToken(),
      'Content-Type': 'application/json'
    },
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    logger.log(data)
    // var items = data.Items.map((item, idx) => {
    //   const keys = Object.keys(item);
    //   var newItem = {};
    //   newItem = new SampleModel(parseValue(item));
    //   newItem.id = newItem.sampleId;
    //   newItem.descr = newItem.description ? newItem.description : newItem.descr;
    //   newItem.stylenumber = newItem.targetSort;
    //   return newItem
    // })

    // //items = items.splice(0,50)
    // logger.log(items, ' getsamples')
    return data
    //return imgUploadUrl;
  })
    .catch((err) => {
      console.error('ERROR getting samples', err);
      throw (err);
    })
}